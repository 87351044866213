<template>
  <v-container>
    <v-autocomplete
      v-model="repicients"
      clearable
      deletable-chips
      multiple
      small-chips
      label="Empfänger"
      :items="emails"
    ></v-autocomplete>
    <v-text-field
      v-model="broadcastSubject"
      outlined
      label="Betreff"
    ></v-text-field>
    <v-textarea v-model="broadcastMsg" outlined label="eMail Text"></v-textarea>
    <v-file-input
      v-model="broadcastAttachment"
      small-chips
      label="Attachment"
      show-size
    ></v-file-input>
    <v-btn elevation="2" class="ma-2" @click="sendTestMail()">Test-Mail</v-btn>
    <br />
    <v-alert border="top" colored-border type="info" elevation="2" class="mx-8"
      >Bitte vor dem finalen Senden eine TestMail erzeugen und im Postfach auf
      Korrektheit überprüfen!
    </v-alert>
    <br />
    <v-btn color="red" elevation="2" class="ma-2 white--text" @click="send()"
      >Senden</v-btn
    >
    <v-alert
      border="top"
      colored-border
      type="success"
      elevation="2"
      class="mx-8"
      >Gesendete eMails können im Postausgang von
      <strong>{{ broadCastAddress }}</strong> überprüft werden
    </v-alert>
    <v-dialog v-model="testDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">
          Test-Mail senden?
        </v-card-title>
        <v-card-text
          >Zu welcher eMail-Adresse soll die TestMail gesendet werden? <br />
          <v-text-field
            v-model="testAdress"
            label="eMail-Adresse"
            solo
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="testDialog = false">
            Abbrechen
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              send(new Array(testAdress));
              testDialog = false;
            "
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const Parse = require('parse');

export default {
  data() {
    return {
      broadCastAddress: process.env.VUE_APP_BROADCAST_ADDRESS,
      testDialog: false,
      testAdress: '',
      repicients: [],
      broadcastSubject: 'Zuckerstachel Ferienlager 2023',
      broadcastAttachment: null,
      broadcastMsg: `Liebe Familien,

wir möchten Ihnen und Ihrem Kind die Unterlagen für die Teilnahme am diesjährigen Kinderferienlager für Kinder mit Typ 1 Diabetes und eine Einladung zum Familienwochenende Im Eurohof senden.
Das Familienwochenende findet vom 01.04.22 bis 03.04.22 statt und ist auf 15 Familien begrenzt. Bei Interesse bei mir per Mail anmelden. Siehe Anhang.
Bitte lesen Sie die Unterlagen durch und senden Sie diese mir ausgefüllt per Mail oder Post zurück. Bei Fragen stehe ich Ihnen gern zur Verfügung.

Herzliche Grüße
Torsten Plachta
Zuckerstachel e. V.`
    };
  },

  computed: {
    emails() {
      return this.$store.state.repicients.map(e => e.email);
    }
  },

  created() {
    this.loadRepicients();
    this.repicients = this.emails;
  },

  methods: {
    async loadRepicients() {
      if (!Parse.User.current()) {
        return;
      }
      await this.$store
        .dispatch('ACT_GET_REPICIENTS')
        .then(() => {
          this.repicients = this.$store.state.repicients.map(e => e.email);
        })
        .catch(err => {
          console.error(`Fehler beim Laden der Emfänger: ${err}!`);
        })
        .finally(() => {});
    },

    sendTestMail() {
      this.testDialog = true;
    },

    async send(testAdress) {
      if (!Parse.User.current()) {
        return;
      }

      const data = {
        subject: this.broadcastSubject,
        msg: this.broadcastMsg,
        repicients: testAdress || this.repicients
      };

      this.$root.$loader.open();

      if (this.broadcastAttachment) {
        const parseFile = new Parse.File(
          this.broadcastAttachment.name,
          this.broadcastAttachment
        );
        await parseFile.save();
        data.attachment = parseFile;
        data.attachmentName = this.broadcastAttachment.name;
      }
      await this.$store
        .dispatch('ACT_SEND_BROADCAST', data)
        .then(() => {
          this.$root.$info.open({
            title: 'BroadCast Mail',
            msg: `${data.repicients.length} ${
              data.repicients.length === 1 ? 'Mail' : 'Mails'
            } versendet`
          });
        })
        .catch(err => {
          this.$root.$info.open({
            title: 'Fehler beim Senden',
            msg: err
          });
        })
        .finally(() => {
          this.$root.$loader.close();
        });
    }
  }
};
</script>

<style></style>
